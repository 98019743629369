import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

import Checkout from '../ui-component/stripe/Checkout';
import Success from '../ui-component/stripe/Success';
import Canceled from '../ui-component/stripe/Canceled';

// sample page routing
// const SamplePage = Loadable(lazy(() => import('../views/BuyCredits')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const StripeRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/success',
                '/canceled',
                '/checkout'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <Route path="/success">
                        <Success />
                    </Route>
                    <AuthGuard>
                        <Route path="/canceled">
                            <Canceled />
                        </Route>
                        <Route path="/checkout">
                            <Checkout />
                        </Route>
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default StripeRoutes;
