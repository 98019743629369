import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Formik, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import FileSaver from 'file-saver';
import styled from "@emotion/styled";

import { Document, Page, pdfjs  } from 'react-pdf';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { makeStyles } from '@material-ui/styles';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField, ToggleButton, ToggleButtonGroup, Select,
    Typography,
    useMediaQuery, MenuItem, Modal, Divider
} from '@material-ui/core';

import states from "../assets/states";
import EarningCard from "../views/dashboard/Default/EarningCard";
import AnimateButton from "./extended/AnimateButton";
import {gridSpacing} from "../store/constant";
import axios from "axios";
import configData from "../config";
import * as Buffer from "buffer";
import TotalIncomeCard from "./cards/Skeleton/TotalIncomeCard";
// import "./styles.css";
// import "./styles-custom.css";
// import { StyledEngineProvider } from '@mui/material/styles';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '90%',
    height: '95%',
    transform: 'translate(-50%, -50%)',
    overflow:'scroll',
    boxShadow: 24,
};

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const useStyles = makeStyles((theme) => ({
    list: {
        overflowY: "auto",
        margin: 0,
        padding: 0,
        listStyle: "none",
        height: "100%",
        '&:-webkit-scrollbar': {
            width: '0.4em'
        },
        '&:-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&:-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    card: {
        backgroundColor: theme.palette.primary.dark,
        color: '#fff',
        overflow: 'hidden',
        position: 'relative',
        '&>div': {
            position: 'relative',
            zIndex: 5
        },
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '210px',
            height: '210px',
            background: theme.palette.primary[800],
            borderRadius: '50%',
            zIndex: 1,
            top: '-85px',
            right: '-95px',
            [theme.breakpoints.down('xs')]: {
                top: '-105px',
                right: '-140px'
            }
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            width: '210px',
            height: '210px',
            background: theme.palette.primary[800],
            borderRadius: '50%',
            top: '-125px',
            right: '-15px',
            opacity: 0.5,
            [theme.breakpoints.down('xs')]: {
                top: '-155px',
                right: '-70px'
            }
        }
    },
    content: {
        padding: '20px !important'
    },
    avatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.largeAvatar,
        backgroundColor: theme.palette.primary[800],
        color: '#fff',
        marginTop: '8px'
    },
    cardHeading: {
        fontSize: '2.125rem',
        fontWeight: 500,
        marginRight: '8px',
        marginTop: '14px',
        marginBottom: '6px'
    },
    subHeading: {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.palette.primary[200]
    },
    avatarCircle: {
        ...theme.typography.smallAvatar,
        cursor: 'pointer',
        backgroundColor: theme.palette.primary[200],
        color: theme.palette.primary.dark
    },
    circleIcon: {
        transform: 'rotate3d(1, 1, 1, 45deg)'
    },
    redButton: {
        fontSize: '1rem',
        fontWeight: 500,
        backgroundColor: theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.grey[100],
        color: theme.palette.grey[700],
        textTransform: 'none',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.875rem'
        }
    },
    signDivider: {
        flexGrow: 1
    },
    signText: {
        cursor: 'unset',
        margin: theme.spacing(2),
        padding: '5px 56px',
        borderColor: theme.palette.grey[50] + ' !important',
        color: theme.palette.grey[50] + '!important',
        fontWeight: 500
    },
    loginIcon: {
        marginRight: '16px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '8px'
        }
    },
    loginInput: {
        ...theme.typography.customInput
    }
}));



// And now we can use these
const ReportForm = ({isLoading, remainingReports, setRemainingReports}) => {
    const userId = localStorage.getItem("userId");
    console.log(userId)
    const classes = useStyles();
    const [file, SetFile] = React.useState(new Blob);
    const [fileName, SetFileName] = React.useState("");
    const [pdfFile, setPdfFile] = React.useState(new Uint8Array([]));
    const [fileUrl, SetFileUrl] = React.useState("");
    const [stateId, setStateId] = React.useState('NY');
    const [reportType, setReportType] = React.useState('carfax');
    const [vin, setVin] = React.useState("");
    const [plateId, setPlateId] = React.useState("");
    const [isValidReport, SetIsValidReport] = React.useState(false);
    const [buttonText, SetButtonText] = React.useState("Search");
    const [buttonSuccessText, SetButtonSuccessText] = React.useState("");
    const [carInfo, SetCarInfo] = React.useState("");
    const [isErrorButton, SetIsErrorButton] = React.useState(false);
    const [isFetching, setFetching] = React.useState(false);
    const [isSubmitted, setSubmitted] = React.useState(false);
    const [allowRestart, setAllowRestart] = React.useState(false);
    const [buttonOpenReport, SetButtonOpenReport] = React.useState(false);
    const [buttonViewReport, SetButtonViewReport] = React.useState(false);
    const [needReplenishment, SetNeedReplenishment] = React.useState(false);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const clearStates = () =>{
        console.log("Clearing states")
        setStateId('NY')
        setReportType('carfax')
        setVin("")
        setPlateId("")
        SetIsValidReport(false)
        SetButtonText("Search")
        SetCarInfo("")
        SetIsErrorButton(false)
        SetCarInfo("")
        SetButtonSuccessText("Last Report")
        setFetching(false);
        setSubmitted(false);
    }
    const fetchReport = () => {
        setFetching(true);
        SetButtonText(`Fetching...`);
        SetCarInfo("This may take up to 45 Secs. Contact us with any issues.")
        console.log("Fetching")
        axios
            .post( configData.API_SERVER + '/getreport', {
                vin: vin,
                stateId: stateId,
                platedId: plateId,
                reportType: reportType,
                carInfo: carInfo
            },{headers:{token: localStorage.getItem("token")}}).then(res=>{
            console.log(res);
            if(res.data){
                SetButtonSuccessText("")
                SetButtonText(`New Search`)
                SetIsValidReport(false)
                SetButtonOpenReport(true)
                SetFileName(res.data.fileName)
                let fileBlob = new Blob([new Uint8Array(res.data.buffer.data).buffer], {type: 'application/pdf'});
                SetFile(fileBlob)
                SetFileUrl(URL.createObjectURL(fileBlob));
                setPdfFile(new Uint8Array(res.data.buffer.data))
                console.log(`../userReports/${userId}/${fileName}`)
                setRemainingReports(res.data.reportsRemaining)
                if(res.data.reportsRemaining===0){
                    setFetching(false);
                    SetButtonViewReport(true);
                    SetCarInfo(`That was your last report 🤧`)
                }
                else if(res.data.reportsRemaining===1){
                    setFetching(false);
                    SetCarInfo(`${res.data.reportsRemaining} REPORT LEFT`)
                    SetButtonViewReport(true);
                }else{
                    setFetching(false);
                    SetCarInfo(`${res.data.reportsRemaining} REPORTS LEFT`)
                    SetButtonViewReport(true);
                }
                setAllowRestart(true);


            }else{
                SetCarInfo("We couldn't find a matching vehicle - Check your input")
                SetIsErrorButton(true);
            }

        })
    }

    function downloadReport(){
        FileSaver.saveAs(file, fileName);
    }
    const ViewReportModal = () =>{
        const defaultLayoutPluginInstance = defaultLayoutPlugin({
            sidebarTabs: (defaultTabs) => [
                defaultTabs[0] // Thumbnails tab
            ],
        });
        return (
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <AnimateButton>
                        <Button
                            type="button"
                            disableElevation
                            //disabled={!isSubmitting}
                            fullWidth
                            size="extra-small"
                            onClick={handleClose}
                            variant="contained"
                            color="warning">Close
                        </Button>
                    </AnimateButton>
                    <Grid container spacing={gridSpacing} justifyContent="center">
                        <Grid item xs={12} md={12} lg={12} style={{
                            //height: '500vh',
                            width: '500vh',
                            marginLeft: 'auto',
                            marginRight: 'auto',}}>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
                                <Viewer theme="dark"
                                        fileUrl={pdfFile}
                                    //plugins={[defaultLayoutPluginInstance]}
                                />

                            </Worker>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        );
    }

    const openReport = () =>{
        window.open(fileUrl)

    }

    const cancelReport = () =>{
        console.log("Canceled")
    }
    const validate = () =>{
        if (plateId.length == 0 && stateId.length==0 && vin.length==0){
            return {
                vin: 'A VIN is required unless a Plate AND State are provided',
            };
        }
        else if (vin.length==0 && (plateId.length>0 && stateId.length==0)){
            return {
                stateId: 'Select a State',

            };
        }
        else if (vin.length==0 && (plateId.length==0 && stateId.length>0)){
            return {
                plateId: 'Enter a License Plate',

            };
        }
        //return error message for assumed filed

    }

    const handleChangeState = (event) => {
        setStateId(event.target.value);
        setVin("");
    };
    const handleChangePlate = (event) => {
        setPlateId(event.target.value);
        setVin("");
    };
    const handleChangeReportType = (event) => {
        setReportType(event.target.value);
    };
    const handleChangeVin = (event) => {
        setVin(event.target.value);
        setPlateId("");
        setStateId("");
    };

   return(
       <React.Fragment>
           {isLoading ? (
               <TotalIncomeCard />
           ) : (
           <Grid container spacing={gridSpacing} justifyContent="center">
               <Grid item xs={12} md={8} lg={8} xl={4}>
       <Formik
        initialValues={{
            vin: vin,
            licensePlate: plateId,
            stateId: stateId,
            reportType: reportType // added for our select
        }}
        validationSchema={Yup.object({
            vin: Yup.string()
                .max(17, "Must be 11 - 17 characters long")
                .min(11, "Must be 11 - 17 characters long"),
            licensePLate: Yup.string()
                .max(9, "Must be 9 characters or less"),
            state: Yup.string()
                .max(2, "Must be 2 characters"),
            reportType: Yup.string()
                // specify the set of valid values for job type
                // @see http://bit.ly/yup-mixed-oneOf
                .oneOf(
                    ["carfax", "autocheck"],
                    "Invalid Report Type"
                )
                .required("Required")
        })}
        onSubmit={(values, { setSubmitting }) => {

            setSubmitted(true);
                console.log(`Submit Values | ${vin}, ${stateId} , ${plateId}, ${reportType}`)
                SetCarInfo("")
                axios
                    .post( configData.API_SERVER + '/checkreport', {
                        vin: vin.toUpperCase(),
                        stateId: stateId,
                        plateId: plateId.toUpperCase(),
                        reportType: reportType
                    },{headers:{token: localStorage.getItem("token")}}).then(res=>{
                    console.log(res);
                    console.log(res.data)
                    if(res.data.reportsRemaining === 0) {
                        SetCarInfo("Please replenish your account.")
                        SetNeedReplenishment(true)
                    }
                    if(res.data.status){
                        SetIsValidReport(true)
                        if(res.data.reportsRemaining === 0){
                            SetButtonText(`Replenish Your Account To Get Report | ${res.data.records} Records`);
                            SetCarInfo(res.data.vehicle + " | Replenish Your Account");
                        }else{
                            SetButtonText(`Get Report | ${res.data.records} Records`);
                            SetCarInfo(res.data.vehicle);
                        }

                        setAllowRestart(true);
                    }
                    else
                    {
                        SetCarInfo("We couldn't find a matching vehicle - Check your input")
                        SetIsErrorButton(true);
                        setAllowRestart(true);
                    }
                })
                //new Promise(r => setTimeout(r, 2000));
            setSubmitting(true);
        }}
        validate={validate}
        validateOnChange={true}
        enableReinitialize={true}
    >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
                <FormControl fullWidth error={Boolean(touched.vin && errors.vin)} className={classes.loginInput}>
                    <InputLabel htmlFor="outlined-adornment-vin">VIN</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-vin"
                        type="text"
                        value={vin.toUpperCase()}
                        disabled={isSubmitted}
                        name="vin"
                        autoCapitalize="characters"
                        onBlur={handleBlur}
                        onChange={handleChangeVin}
                        label="vin"
                        inputProps={{
                            classes: {
                                notchedOutline: classes.notchedOutline
                            }
                        }}
                    />
                    {touched.vin && errors.vin && (
                        <FormHelperText error id="standard-weight-helper-text-vin">
                            {' '}
                            {errors.vin}{' '}
                        </FormHelperText>
                    )}
                </FormControl>
                <InputLabel>OR</InputLabel>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={6} md={6} >
                        <FormControl fullWidth error={Boolean(touched.plateId && errors.plateId)}  className={classes.loginInput}>
                            <InputLabel fullWidth htmlFor="outlined-adornment-license-plate">License Plate</InputLabel>
                            <OutlinedInput
                                fullWidth
                                id="outlined-adornment-license-plate"
                                type="text"
                                disabled={isSubmitted}
                                value={plateId.toUpperCase()}

                                name="licensePlate"
                                onBlur={handleBlur}
                                onChange={handleChangePlate}
                                label="license Plate"
                                inputProps={{
                                    classes: {
                                        notchedOutline: classes.notchedOutline
                                    }
                                }}
                            />
                            {errors.plateId && (
                                <FormHelperText error id="standard-weight-helper-text-license-plate">
                                    {' '}
                                    {errors.plateId}{' '}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <FormControl fullWidth error={Boolean(touched.stateId && errors.stateId)} >
                            <InputLabel fullWidth htmlFor="select-state-label" style={{ marginTop: 8,
                                marginBottom: 8,
                                '& > label': {
                                    top: '23px',
                                    left: 0,
                                    '&[data-shrink="false"]': {
                                        top: '5px'
                                    }
                                },
                                '& > div > input': {
                                    padding: '30.5px 14px 11.5px !important'
                                },
                                '& legend': {
                                    display: 'none'
                                },
                                '& fieldset': {
                                    top: 0
                                }}}>State</InputLabel>
                            <Select
                                labelId="select-state-label"
                                fullWidth
                                disabled={isSubmitted}
                                id="state-select"
                                value={stateId}
                                label="State"
                                onChange={handleChangeState}
                                style={{marginTop: 8,
                                    marginBottom: 8,
                                    '& > label': {
                                        top: '23px',
                                        left: 0,
                                        '&[data-shrink="false"]': {
                                            top: '5px'
                                        }
                                    },
                                    '& > div > input': {
                                        padding: '30.5px 14px 11.5px !important'
                                    },
                                    '& legend': {
                                        display: 'none'
                                    },
                                    '& fieldset': {
                                        top: 0
                                    }}}
                            >
                                <MenuItem key="" value="" >
                                    Select State
                                </MenuItem>
                                {states.map(item => (
                                    <MenuItem  key={item.abbreviation} value={item.abbreviation}>
                                        {(item.name) + " - " + (item.abbreviation)}
                                    </MenuItem>
                                ))}
                                {errors.stateId && (
                                    <FormHelperText error id="standard-weight-helper-text-stateId">
                                        {' '}
                                        {errors.stateId}{' '}
                                    </FormHelperText>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={6} md={6}>
                        <FormControl>
                            <InputLabel id="select-reportType-label">Report Type</InputLabel>
                            <Select
                                labelId="select-reportType-label"
                                disabled={isSubmitting}
                                id="reportType-2-select"
                                value={reportType}
                                label="Report Type"
                                onChange={handleChangeReportType}
                            >
                                <MenuItem value="carfax">
                                    Carfax
                                </MenuItem>
                                <MenuItem value="autocheck">
                                    Autocheck
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>

                <AnimateButton>
                    {!isSubmitting && !needReplenishment && !isSubmitted&& <Button disableElevation
                                              id="button1"
                                              name="checkReportButton"
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="secondary">{buttonText}
                    </Button>}
                </AnimateButton>
                    <Typography align="center" variant="subtitle1" fontSize=".75rem">{carInfo}
                    </Typography>
                <AnimateButton>
                    {isValidReport &&<Button
                                              id="button2"
                                                              name="fetchReportButton"
                                             disabled={isFetching || needReplenishment}
                                             fullWidth
                                              type="button"
                                             size="large"
                                             onClick={fetchReport}
                                             variant="contained"
                                             color="warning">{buttonText}
                    </Button>}
            </AnimateButton>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={6} md={6} lg={6}>
                        <AnimateButton>
                            { buttonOpenReport && <Button disableElevation
                                                          disabled={!buttonOpenReport}
                                                          fullWidth
                                                          size="large"
                                                          onClick={handleOpen}
                                                          variant="contained"
                                                          color="success">View {buttonSuccessText}
                            </Button>}
                    </AnimateButton>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                        <AnimateButton>
                            { buttonOpenReport && <Button disableElevation
                                                          disabled={!buttonOpenReport}
                                                          fullWidth
                                                          size="large"
                                                          onClick={downloadReport}
                                                          variant="contained"
                                                          color="success">Save {buttonSuccessText}
                            </Button>}
                    </AnimateButton>
                        </Grid>
                    </Grid>
            <AnimateButton>
                    {(allowRestart || isErrorButton) && !needReplenishment && !isFetching && isSubmitted&& <Button
                        type="button"
                                                                disableElevation
                                                               disabled={isFetching}
                                                               fullWidth
                                                               size="large"
                                                               onClick={clearStates}
                                                               variant="contained"
                                                               color="info">Restart
                    </Button>}
                </AnimateButton>

            </form>
        )}

    </Formik>
           </Grid>
               <Grid item xs={12} md={12} lg={12}>
                   {buttonViewReport && open && <ViewReportModal />}
               </Grid>
           </Grid>)}
</React.Fragment>

)
}

export default ReportForm;

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
