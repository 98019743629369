import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import {ButtonBase, Typography} from '@material-ui/core';

// project imports
import config from './../../../config';
import Logo from './../../../ui-component/Logo';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <Typography variant="h1" width="92" height="32" viewBox="0 0 92 32">
                VINReports
            </Typography>
        </ButtonBase>
    );
};

export default LogoSection;
