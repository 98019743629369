import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
// import {LOGOUT} from "../store/actions";

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const DashboardMyReports = Loadable(lazy(() => import('../views/dashboard/MyReports')));

// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));
// const SignOut = Loadable(lazy(() => import('../views/pages/authentication/signout')));
// sample page routing
const BuyCredits = Loadable(lazy(() => import('../views/BuyCredits')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/search',
                '/my-reports',
                '/buy-credits'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/search" component={DashboardDefault} />
                        <Route path="/my-reports" component={DashboardMyReports} />
                        <Route path="/buy-credits" component={BuyCredits} />
                        {/*<Route path="/utils/util-typography" component={UtilsTypography} />*/}
                        {/*<Route path="/utils/util-color" component={UtilsColor} />*/}
                        {/*<Route path="/utils/util-shadow" component={UtilsShadow} />*/}
                        {/*<Route path="/icons/tabler-icons" component={UtilsTablerIcons} />*/}
                        {/*<Route path="/icons/material-icons" component={UtilsMaterialIcons} />*/}

                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
