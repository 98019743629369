// assets
import { IconDashboard, IconDeviceAnalytics, IconReport, IconCloudDownload, IconShoppingCartPlus } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics, IconReport, IconCloudDownload, IconShoppingCartPlus
};


//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Get Report',
            type: 'item',
            url: '/search',
            icon: icons['IconReport'],
            breadcrumbs: false
        },
        {
            id: 'myreports',
            title: 'My Reports',
            type: 'item',
            url: '/my-reports',
            icon: icons['IconCloudDownload'],
            breadcrumbs: false
        },
        {
            id: 'buycredits',
            title: 'Buy Credits',
            type: 'item',
            url: '/buy-credits',
            icon: icons['IconShoppingCartPlus'],
            breadcrumbs: false
        }
    ]
};
