import { dashboard } from './dashboard';
// import { utilities } from './utilities';
// import { other } from './other';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard]
    // items: [dashboard, utilities, other]
};

export default menuItems;
