import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

// material-ui
import { makeStyles } from '@material-ui/styles';
import {Avatar, Button, Link, List, ListItem, ListItemAvatar, ListItemText, Typography} from '@material-ui/core';

// project imports
import MainCard from './../../../ui-component/cards/MainCard';
import TotalIncomeCard from './../../../ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import Paid from '@material-ui/icons/Paid';
import axios from "axios";
import configData from "../../../config";

// style constant
const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.light,
        overflow: 'hidden',
        position: 'relative'
    },
    content: {
        padding: '16px !important'
    },
    avatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.largeAvatar,
        backgroundColor: theme.palette.primary[800],
        color: '#fff'
    },
    primary: {
        color: '#fff'
    },
    secondary: {
        color: theme.palette.primary.light,
        marginTop: '5px'
    },
    padding: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

//-----------------------|| DASHBOARD - TOTAL INCOME DARK CARD ||-----------------------//


const TotalIncomeDarkCard = ({ isLoading, remainingReports, setRemainingReports}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchRemainingReports();
    }, []);

    const fetchRemainingReports =()=>{
        setLoading(true);
        axios.post( configData.API_SERVER + '/getremainingreports', {
        },{headers:{token: localStorage.getItem("token")}}).then(res=>{
            //console.log(res);
            if(res.data) {
                setRemainingReports(res.data.user.remainingReports);
            }
            setLoading(false);
        })

    }

    return (
        <React.Fragment>
            {isLoading && loading ? (
                <TotalIncomeCard />
            ) : (
                <MainCard border={false} className={classes.card} contentClass={classes.content}>
                    <List className={classes.padding}>
                        <ListItem alignItems="center" disableGutters className={classes.padding}>
                            <ListItemAvatar>
                                <Avatar variant="rounded" className={classes.avatar}>
                                    <Paid fontSize="inherit" />
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                                className={classes.padding}
                                sx={{
                                    mt: 0.45,
                                    mb: 0.45
                                }}
                                primary={
                                    <Typography variant="h2" className={classes.primary}>
                                        {remainingReports}
                                    </Typography>
                                }
                                secondary={
                                    <Typography variant="subtitle2" className={classes.primary}>
                                        {remainingReports === 1 ? 'Report Left' : 'Reports Left'}
                                    </Typography>
                                }

                            />

                                <Button disableElevation
                                        variant="contained"
                                        color="secondary"
                                        href="/buy-credits">Buy More
                                </Button>

                        </ListItem>

                    </List>
                </MainCard>
            )}
        </React.Fragment>
    );
};

TotalIncomeDarkCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalIncomeDarkCard;
