import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from "axios";
import configData from "../../config";
import {ACCOUNT_INITIALIZE, LOGOUT} from "../../store/actions";

//-----------------------|| AUTH GUARD ||-----------------------//

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();
    console.log(account);
    const { isLoggedIn } = account;
    const { token } = account;

    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    }

    axios
        .post( configData.API_SERVER + '/verify-user', {},{headers:{token: token}})
        .then(function (response) {
            if (!response.data.isLoggedIn) {
                dispatcher({ type: LOGOUT });
                localStorage.clear();
                return <Redirect to="/login" />;
            }
        })



    return children;
};

AuthGuard.propTypes = {
    children: PropTypes.node
};

export default AuthGuard;
