import React, {useEffect, useState} from 'react';
import configData from '../../config';
import {
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Grid, Slider,
    Typography
} from "@material-ui/core";
import ReportCard from "../../views/dashboard/MyReports/reportCard";
import axios from "axios";
import {gridSpacing} from "../../store/constant";
import AnimateButton from "../extended/AnimateButton";

const formatPrice = ({ amount, currency, quantity =1 }) => {
    //console.log(amount, currency, quantity)
    const numberFormat = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
    });
    const parts = numberFormat.formatToParts(amount);
    let zeroDecimalCurrency = true;
    for (let part of parts) {
        if (part.type === 'decimal') {
            zeroDecimalCurrency = false;
        }
    }
    amount = zeroDecimalCurrency ? amount : amount / 100;
    const total = (quantity * amount).toFixed(2);
    //console.log(numberFormat.format(total))
    return numberFormat.format(total);

};
const formatPriceBundle = ({ unit_amount, currency }) => {
    const fraction = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currencyDisplay: 'symbol',
    });

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        currencyDisplay: 'symbol',
    });

    const total = (unit_amount/100);
    console.log(unit_amount, currency)
    if(total % 1 == 0)
        return(fraction.format(total));
    else
        return(formatter.format(total));

};
const submitPurchaseRequest = (amount, currency, quantity, priceid)=>{
    axios
        .post( configData.API_SERVER + '/create-checkout-session', {
            amount, currency, quantity

        },{headers:{token: localStorage.getItem("token"), priceid: priceid}}).then(res=>{

        if(res.data.status === true){
            window.location.href=res.data.stripeSessionUrl;
        }
    })
}

const Checkout = ({stripeProductId}) => {
    const [priceArr, setPriceArr] = useState([]);
    const [priceIdSingles, setPriceIdSingles] = useState("");
    const [singlePrice, setSinglePrice] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [amount, setAmount] = useState(0);
    const [currency, setCurrency] = useState('USD');
    const [isLoading, setLoading] = useState(false);


    const handleChange = (event, newValue) => {
        setQuantity(newValue);
    };

    useEffect(() => {
        async function fetchConfig() {
            // Fetch config from our backend.
            await fetch(configData.API_SERVER + '/config', {
                headers: {
                    token: localStorage.getItem("token"),
                    productId: stripeProductId
                }
            }).then(res => res.json()).then(async res =>{
                // console.log(res);
                // console.log(res.status)
                // console.log(res.prices)
                if(res.status == true){
                    console.log("in if")
                    setPriceArr(res.prices)
                    setPriceIdSingles(res.basePriceId)
                }
                // console.log(priceArr)
                // console.log(priceIdSingles)
                let singlePriceObj = await res.prices.find(x => x.id == res.basePriceId);

                setSinglePrice(singlePriceObj)
                setAmount(singlePriceObj.unit_amount);
                setCurrency(singlePriceObj.currency);

                //console.log(singlePriceObj)
                setLoading(true);

            });

        }
        fetchConfig();
    }, []);



    return (
        <React.Fragment>
        {isLoading &&(
        <Card>
            <Grid container spacing={gridSpacing} justifyContent={"center"}>
            <CardContent>
                <Typography align="center" variant={"h1"}>{quantity}</Typography>
                <Typography align="center" variant={"h1"}>{quantity===1? "Report" : "Reports"}</Typography>
                <Slider
                    aria-label="Quantity"
                    defaultValue={1}
                    onChange={handleChange}
                    value={quantity}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={4}
                />
                <Button key="12e"
                    disableElevation
                        //disabled={!buttonOpenReport}
                        fullWidth
                        size="large"
                        onClick={()=>submitPurchaseRequest(amount, currency, quantity, priceIdSingles)}
                        variant="contained"
                        color="success">  Buy {formatPrice({amount, currency, quantity})}
                </Button>
            </CardContent>
            </Grid>

            <Grid container spacing={gridSpacing} justifyContent={"center"}>
            <Divider />

                {priceArr.map(({id, unit_amount, currency, nickname}) =>
                    (id!== priceIdSingles &&
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button key={id}
                                //disableElevation
                                sx={{mr: .5}}
                            //disabled={!buttonOpenReport}
                                fullWidth
                                size="small"
                                onClick={()=>submitPurchaseRequest(unit_amount, currency, 1,id)}
                                variant="contained"
                                color="secondary">
                            {nickname} | {formatPriceBundle({unit_amount, currency})}
                        </Button>
                        </Grid>
                    ))}

            </Grid>
        </Card>)}
            </React.Fragment>
    );
};

export default Checkout;
