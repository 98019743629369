import PropTypes from 'prop-types';
import React, {useState} from 'react';
import FileSaver from 'file-saver';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import ReactTimeAgo from 'react-time-ago'
// material-ui
import { makeStyles } from '@material-ui/styles';
import {
    Avatar, Box,
    Button,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, Modal,
    Typography
} from '@material-ui/core';
import { gridSpacing } from '../../../store/constant';
// project imports
import MainCard from './../../../ui-component/cards/MainCard';
import carfaxLogo from './../../../assets/images/carfax_c_logo.png';
import TotalIncomeCard from './../../../ui-component/cards/Skeleton/TotalIncomeCard';
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import {ImageRounded} from "@material-ui/icons";
import Paid from "@material-ui/icons/Paid";
import AnimateButton from "../../../ui-component/extended/AnimateButton";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";
import {SpecialZoomLevel, Viewer, Worker} from "@react-pdf-viewer/core";

// assets
TimeAgo.addDefaultLocale(en)

// style constant
const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.dark,
        overflow: 'hidden',
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            width: '210px',
            height: '210px',
            background: 'linear-gradient(210.04deg, ' + theme.palette.grey200 + ' -50.94%, rgba(144, 202, 249, 0) 83.49%)',
            borderRadius: '50%',
            top: '-30px',
            right: '-180px'
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            width: '210px',
            height: '210px',
            background: 'linear-gradient(140.9deg, ' + theme.palette.grey200 + ' -14.02%, rgba(144, 202, 249, 0) 77.58%)',
            borderRadius: '50%',
            top: '-160px',
            right: '-130px'
        }
    },
    content: {
        padding: '16px !important'
    },
    avatar: {
        ...theme.typography.commonAvatar,
        ...theme.typography.largeAvatar,
        backgroundColor: theme.palette.grey[50],
        color: '#2174de'
    },
    primary: {
        color: '#fff'
    },
    secondary: {
        color: theme.palette.primary.light,
        marginTop: '2px'
    },
    padding: {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

//-----------------------|| DASHBOARD - TOTAL INCOME DARK CARD ||-----------------------//

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '95%',
    height: '95%',
    transform: 'translate(-50%, -50%)',
    overflow:'scroll',
    boxShadow: 24,
};
const ReportCard = ({  vin, carInfo, date, pdfArray, reportType, filename}) => {
    const classes = useStyles();
    const [remainingReports, setRemainingReports] = useState(0)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [pdfFile, setPdfFile] = useState(new Uint8Array(pdfArray.data))

    const [file, SetFile] = React.useState(new Blob);
    const [fileName, SetFileName] = React.useState(filename);
    //SetFile(new Blob([new Uint8Array(pdfArray.data).buffer], {type: 'application/pdf'}))
    //
    function downloadReport(){
        FileSaver.saveAs(new Blob([new Uint8Array(pdfArray.data).buffer], {type: 'application/pdf'}), fileName);
    }

    const ViewReportModal = () =>{
        const defaultLayoutPluginInstance = defaultLayoutPlugin({
            sidebarTabs: (defaultTabs) => [
                defaultTabs[0] // Thumbnails tab
            ],
        });
        return (
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={style}>

                        <AnimateButton>
                            <Button
                                type="button"
                                disableElevation
                                fullWidth
                                size="extra-small"
                                onClick={handleClose}
                                variant="contained"
                                color="warning">Close
                            </Button>
                        </AnimateButton>
                        <Grid container spacing={gridSpacing} justifyContent="center">
                            <Grid item xs={12} md={12} lg={12} style={{
                                //height: '500vh',
                                width: '600vh',
                                marginLeft: '2px',
                                marginRight: '2px',}}>
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
                                    <Viewer theme="dark"
                                            fileUrl={pdfFile}
                                            //plugins={[defaultLayoutPluginInstance]}
                                    />

                                </Worker>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
        );
    }



    return (
        <React.Fragment>
                    <MainCard className={classes.card} contentClass={classes.content}>
                        <Grid item>

                            <List className={classes.padding}>

                                <ListItem alignItems="left" disableGutters className={classes.padding}>
                                    <ListItemAvatar>
                                        <Avatar variant="rounded" className={classes.avatar}>
                                            {reportType==='carfax'?<img src={carfaxLogo} alt="carfax_logo" className={classes.avatar}/> :
                                                <CheckBoxOutlinedIcon color="info" alt="autocheck_logo" className={classes.avatar}/>}
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText
                                        sx={{
                                            mt: 0.45,
                                            mb: 0.45
                                        }}
                                        primary={<Typography variant="h5" className={classes.primary}>{carInfo}</Typography>}
                                        secondary={
                                            <Typography variant="subtitle2" className={classes.secondary}>
                                                {vin}
                                            </Typography>}
                                    />
                                    <ListItemText>
                                        <AnimateButton>
                                            <Button
                                                type="button"
                                                disableElevation
                                                //disabled={!isSubmitting}
                                                fullWidth
                                                size="extra-small"
                                                onClick={handleOpen}
                                                variant="contained"
                                                color="info">View
                                            </Button>
                                        </AnimateButton>
                                        <AnimateButton>
                                            <Button
                                                type="button"
                                                disableElevation
                                                //disabled={!isSubmitting}
                                                fullWidth
                                                size="extra-small"
                                                onClick={downloadReport}
                                                variant="contained"
                                                color="success">download
                                            </Button>
                                        </AnimateButton>
                                    </ListItemText>


                                </ListItem>



                            </List>
                            <Divider></Divider>
                            <Typography variant="subtitle2" className={classes.secondary}>
                                <ReactTimeAgo date={date} timeStyle="twitter-now"/>
                            </Typography>
                        </Grid>
                        {open && <ViewReportModal/>}
                    </MainCard>

        </React.Fragment>
    );
};

ReportCard.propTypes = {
    vin: PropTypes.string,
    carInfo: PropTypes.string,
    date: PropTypes.any,
    pdfArray: PropTypes.any,
    reportType: PropTypes.string,
    filename: PropTypes.string
};

export default ReportCard;
